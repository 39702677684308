<template>
    <div class="modal-privacy-agreement">
        <header class="header">
            <h1 class="title">회원 개인정보 보호 동의 및 주선 목적 외 사용 금지 안내</h1>
            <p class="desc">
                회원의 개인정보를 무단으로 공유, 사용하여 회원 혹은 회사에 피해가 발생하는 경우<br />
                회사는 민형사상의 조치를 취할 수 있습니다.
            </p>
        </header>

        <main class="main">
            <section class="section">
                <h2 class="title">개인정보 보호</h2>
                <p class="desc m-b-24">
                    회원 검색은 구체적인 정보로 검색할 수 있는 만큼, 더더욱 회원의
                    <span>개인정보보호에 각별히 유념</span>하여야 합니다.<br />
                    회원의 프로필, 사진 정보를
                    <span class="text-underline">지인 혹은 제 3자, 혹은 대중에게 공유하는 것은 엄격하게 금지</span
                    >됩니다.
                </p>
                <div class="examples">
                    <div>
                        <p class="example">사례 1) 프로필을 캡쳐하여 친구에게 카톡으로 공유</p>
                        <img class="image" src="@/assets/images/modal/privacy-agreement/privacy-ex-1.png" />
                    </div>
                    <div>
                        <p class="example">사례 2) 프로필의 일부를 모자이크 처리하여 인터넷에 게시</p>
                        <img class="image" src="@/assets/images/modal/privacy-agreement/privacy-ex-2.png" />
                    </div>
                </div>
            </section>

            <hr class="hr" />

            <section class="section">
                <h2 class="title">주선 목적 외 사용 금지</h2>
                <p class="desc m-b-24">
                    회원 간 소개팅 주선 목적 이외에 회원 검색 기능을 사용하는 것을 엄격하게 금지합니다.
                </p>
                <div class="examples">
                    <div>
                        <p class="example">
                            사례 1) 회원 검색으로 친구가 가입했는지 찾아본 뒤에 연락해서<br />
                            "너 바닐라브릿지 쓰더라" 라고 말함
                        </p>
                        <img class="image" src="@/assets/images/modal/privacy-agreement/privacy-ex-3.png" />
                    </div>
                    <div>
                        <p class="example">
                            사례 2) 지인이 본인 남자친구가 이 앱을 쓰는지 궁금하다고 해서<br />
                            회원 검색을 해본 뒤 가입 유무를 알려줌
                        </p>
                        <img class="image" src="@/assets/images/modal/privacy-agreement/privacy-ex-4.png" />
                    </div>
                </div>
            </section>
        </main>

        <footer class="footer">
            <div class="agreement-text m-b-20">
                <p class="desc">내용에 동의하신다면, 아래 텍스트를 직접 입력해주세요</p>
                <p class="text">{{ text }}</p>
            </div>
            <textarea class="m-b-20" :placeholder="'텍스트를 직접 입력해주세요'" v-model="userText" />
            <button class="btn btn-primary" :class="{ disabled: !buttonEnabled }" @click="onClickAgreement">
                동의하고 사용하기
            </button>
        </footer>
    </div>
</template>
<script>
import agentService from '@/services/agent'

export default {
    name: 'ModalPrivacyAgreement',
    props: ['options'],
    data: () => ({
        text:
            '본인은 회원 개인정보 보호 및 주선 목적 외 사용 금지에 관한 안내 내용을 숙지하였으며, 위반에 따른 책임은 본인에 있음에 동의합니다.',
        userText: '',
    }),
    computed: {
        buttonEnabled() {
            return this.text === this.userText
        },
    },
    methods: {
        onClickAgreement() {
            if (!this.buttonEnabled) {
                this.$toast.error('텍스트 내용이 일치하지 않습니다')

                return
            }
            this.updateAgent().then(() => {
                this.$toast.success('이제부터 회원 검색 기능을 사용할 수 있어요')
                this.$emit('close')
            })
        },
        async updateAgent() {
            try {
                this.$loading(true)
                const agent = this.$store.getters.agent
                agent.user_privacy_terms_version = 1

                await agentService.update(agent)
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-privacy-agreement {
    padding: 0;
    width: 900px;
    max-height: 820px;
    overflow-y: auto;

    & > * {
        line-height: normal;
    }
    h1,
    h2,
    p {
        margin: 0;
    }

    .header,
    .main,
    .footer {
        padding: 24px 20px;
        width: 100%;
    }

    .header {
        border-bottom: solid 1px $grey-03;

        .title {
            margin-bottom: 8px;
            font-size: 18px;
            color: black;
            font-weight: bold;

            @include f-bold;
        }

        .desc {
            font-size: 13px;
            color: $grey-08;
        }
    }

    .main {
        border-bottom: solid 1px $grey-03;

        .title {
            margin-bottom: 8px;
            font-size: 16px;
            color: black;
            font-weight: 500;

            @include f-medium;
        }

        .desc {
            font-size: 13px;
            color: black;

            span {
                color: $pink-deep;

                @include f-medium;
            }
        }

        .hr {
            margin: 24px 0;
            border-bottom: 1px solid $grey-02;
        }
    }

    .examples {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        .example {
            padding: 0 8px;
            margin-bottom: 8px;
            display: inline-block;
            background-color: $grey-09;
            border-radius: 2px;
            color: white;
            font-size: 12px;
            line-height: 1.33;
            font-weight: 500;

            @include f-medium;
        }

        .image {
            width: 100%;
            height: 100%;
        }
    }

    .footer {
        & > * {
            width: 100%;
        }

        .agreement-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: 500;

            @include f-medium;

            .desc {
                margin-bottom: 8px;
                font-size: 16px;
                color: black;
            }

            .text {
                font-size: 14px;
                color: $purple-primary;
                user-select: none;
            }
        }

        textarea {
            padding: 10px 16px;
            height: 80px;
        }

        .btn {
            height: 48px;
            font-size: 13px;

            @include f-medium;

            &[disabled]:hover {
                background-color: #f1f2f4;
            }
        }
    }
}
</style>
